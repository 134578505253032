import React from 'react';
import { injectIntl } from "gatsby-plugin-intl"
const Footer = ({ intl, trackingSection }) => (
    <div className="block footer" data-section={trackingSection}>
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <ul>
                        <li>
                            <a
                                href="https://www.fisglobal.com"
                                title="Visit us"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_visit" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.fisglobal.com/privacy"
                                title="Privacy policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_privacy" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.fisglobal.com/cookies"
                                title="Cookies"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_cookies" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.fisglobal.com/en/terms-of-use"
                                title={intl.formatMessage({ id: "footer_terms" })}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_terms" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.fisglobal.com/login"
                                title="Login"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_login" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.fisglobal.com/contact-us"
                                title="Contact us"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_contact" })}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://empower1.fisglobal.com/preferences-registration.html?utm_source=footerlink&utm_medium=fisglobal&utm_campaign=preferencecentergdpr"
                                title={intl.formatMessage({ id: "footer_preference" })}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.formatMessage({ id: "footer_preference" })}
                            </a>
                        </li>
                    </ul>
                    <p>{intl.formatMessage({ id: "footer_copy4" })}</p>
                </div>
            </div>
        </div>
    </div>
);

export default injectIntl(Footer);
